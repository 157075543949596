import { Image, Button, DrawerBody, DrawerHeader, DrawerHeaderNavigation, makeStyles, OverlayDrawer, shorthands, Toolbar, ToolbarButton, ToolbarGroup } from '@fluentui/react-components';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import { ArrowLeft24Regular, Dismiss24Regular } from '@fluentui/react-icons';
import { customTokens } from '../../styles';
import { useEffect, useState } from 'react';
import { useChat } from '../../libs/hooks';
import OntLogo from '../../assets/icons/ont_logo_trans.png'

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
    drawerHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    overlayDrawer: {
        backgroundColor: customTokens.overlayDrawerBackground,
    },
    drawerBody: {
        padding: 0,
    },
    toolbarGroup: {
        display: 'flex'
    }
});

export const ChatView = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { isDrawerOpen } = useAppSelector((state: RootState) => state.app);
    const { toggleDrawer } = useChat();

    const closeDrawer = () => { toggleDrawer(false); };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 744);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 744);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={classes.container}>
            {isMobile ? (<div>
                <OverlayDrawer
                    position="start"
                    open={isDrawerOpen}
                    className={`${classes.overlayDrawer} overlayDrawer`}
                    onOpenChange={(_, { open }) => { toggleDrawer(open); }}
                >
                    <DrawerHeader>
                        <DrawerHeaderNavigation className={`${classes.drawerHeader} drawerHeader`}>
                            <Button
                                aria-label="Back"
                                appearance="subtle"
                                icon={<ArrowLeft24Regular />}
                                onClick={() => { closeDrawer(); }}
                            />
                            <Toolbar>
                                <ToolbarGroup className={`${classes.toolbarGroup} toolbarGroup`}>
                                    {/* <ToolbarButton
                                        aria-label="Reload content"
                                        appearance="subtle"
                                        icon={<ArrowClockwise24Regular />}
                                    />
                                    <ToolbarButton
                                        aria-label="Settings"
                                        appearance="subtle"
                                        icon={<Settings24Regular />}
                                    /> */}
                                    <Image src={OntLogo} width={150} />
                                    <ToolbarButton
                                        aria-label="Close panel"
                                        appearance="subtle"
                                        icon={<Dismiss24Regular />}
                                        onClick={() => { closeDrawer(); }}
                                    />
                                </ToolbarGroup>
                            </Toolbar>
                        </DrawerHeaderNavigation>

                        {/* <DrawerHeaderTitle>Chat history</DrawerHeaderTitle> */}
                    </DrawerHeader>

                    <DrawerBody className={`${classes.drawerBody} drawerBody`}>
                        <ChatList />
                    </DrawerBody>
                </OverlayDrawer>
            </div>) : (<ChatList />)}

            {selectedId !== '' && <ChatWindow />}
        </div>
    );
};
