import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { Plugin } from '../../redux/features/plugins/PluginsState';
import { addPlugin } from '../../redux/features/plugins/pluginsSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { PluginManifest, requiresUserLevelAuth } from '../models/PluginManifest';
import { PluginService } from '../services/PluginService';
import { getErrorDetails } from '../../components/utils/TextUtils';
import { AlertType } from '../models/AlertType';
import { addAlert } from '../../redux/features/app/appSlice';
import { RootState } from '../../redux/app/store';

export const usePlugins = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const pluginService = React.useMemo(() => new PluginService(), []);
    const { authConfig } = useAppSelector((state: RootState) => state.app);

    const addCustomPlugin = (manifest: PluginManifest, manifestDomain: string) => {
        const newPlugin: Plugin = {
            name: manifest.name_for_human,
            nameForModel: manifest.name_for_model,
            publisher: 'Custom Plugin',
            description: manifest.description_for_human,
            enabled: false,
            authRequirements: {
                personalAccessToken: requiresUserLevelAuth(manifest.auth),
            },
            headerTag: manifest.name_for_model,
            icon: manifest.logo_url,
            manifestDomain: manifestDomain,
        };

        dispatch(addPlugin(newPlugin));
    };

    const getPluginManifest = React.useCallback(
        async (manifestDomain: string) => {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            return await pluginService.getPluginManifestAsync(manifestDomain, accessToken, authConfig?.userMode);
        },
        [pluginService, inProgress, instance],
    );

    const setPluginStateAsync = async (chatId: string, pluginName: string, enabled: boolean): Promise<void> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await pluginService.setPluginStateAsync(chatId, pluginName, accessToken, authConfig?.userMode, enabled);
    };

    const pluginStatusUpdateAsync = async (pluginName: string, enabled: boolean): Promise<void> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await pluginService.pluginStatusUpdateAsync(pluginName, enabled, accessToken, authConfig?.userMode);
    };

    const loadCustomPlugin = async () => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const plugins = await pluginService.getConnectPluginsAsync(accessToken, authConfig?.userMode);
            plugins.forEach(plugin => {
                const newPlugin: Plugin = {
                    name: plugin.name,
                    nameForModel: plugin.nameForModel,
                    publisher: plugin.publisher,
                    description: plugin.description,
                    enabled: plugin.enabled,
                    authRequirements: {
                        personalAccessToken: false,
                    },
                    headerTag: plugin.headerTag,
                    icon: plugin.icon,
                    manifestDomain: plugin.manifestDomain,
                };
                dispatch(addPlugin(newPlugin));
            });
            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load Plugin. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            return false;
        }
    };

    return {
        addCustomPlugin,
        loadCustomPlugin,
        getPluginManifest,
        setPluginStateAsync,
        pluginStatusUpdateAsync
    };
};
