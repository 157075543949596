import * as AdaptiveCards from "adaptivecards";

// import * as ACFluentUI from "adaptivecards-fluentui";
import { useEffect, useRef } from "react";

export interface AdaptiveCardProps {
  card: any;
}

const AdaptiveCard = ({ card }: AdaptiveCardProps) => {
  const cardWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // // // // if (!cardWrapperRef || !card) return;

    const adaptiveCard = new AdaptiveCards.AdaptiveCard();
    //----------------fluent UI
    // Use Fluent UI controls when rendering Adaptive Cards
    // ACFluentUI.useFluentUI();

    // Set its hostConfig property unless you want to use the default Host Config
    // Host Config defines the style and behavior of a card
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
      // More host config options
    });

    // Set the adaptive card's event handlers. onExecuteAction is invoked
    // whenever an action is clicked in the card
    // // adaptiveCard.onExecuteAction = function (action) {
    // // 	let message = "Action executed\n";
    // // 	message += "    Title: " + action.title + "\n";

    // // 	if (action instanceof AdaptiveCards.OpenUrlAction) {
    // // 		message += "    Type: OpenUrl\n";
    // // 		message += "    Url: " + action.url + "\n";
    // // 	}
    // // 	else if (action instanceof AdaptiveCards.SubmitAction) {
    // // 		message += "    Type: Submit\n";
    // // 		message += "    Data: " + JSON.stringify(action.data);
    // // 	}
    // // 	else {
    // // 		message += "    Type: <unknown>";
    // // 	}

    // // 	alert(message);
    // // }
    //------------------

    adaptiveCard.parse(card);
    if (cardWrapperRef.current) {
      cardWrapperRef.current.innerHTML = "";
      adaptiveCard.render(cardWrapperRef.current);
    }
  }, [card, cardWrapperRef]);

  return <div ref={cardWrapperRef} />;
};

export default AdaptiveCard;
