import { Body1, Caption1, Card, CardFooter, CardHeader, CardPreview, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { ReactElement } from 'react';
import { customTokens } from '../../../styles';

const useClasses = makeStyles({
    main: {
        ...shorthands.gap('36px'),
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    title: {
        ...shorthands.margin(0, 0, tokens.spacingHorizontalM),
    },
    description: {
        // ...shorthands.margin(0, 0, tokens.spacingHorizontalM),
    },
    card: {
        width: '250px',
        maxWidth: '100%',
        height: '200px',
        backgroundColor: '#F6F0F7',

    },
    caption: {
        display: 'none',
        color: customTokens.connectBlue,
        // width: '100%'
    },
    logo: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: '48px',
        height: '48px',
    },
    text: {
        margin: '0px 10px'
        // ...shorthands.margin(tokens.spacingVerticalNone),
    },
    header: {
        // fontWeight: '600'
        textWrap: 'nowrap'
    },
    cardHeader: {
        // flexWrap: 'wrap'
        // width: '100%'
    },
});

interface IBaseCardProps {
    image?: string;
    header: string;
    secondaryText?: string;
    description: string;
    action: ReactElement;
    helpText?: string;
    helpLink?: string;
}

export const BaseCard: React.FC<IBaseCardProps> = ({
    image,
    header,
    secondaryText,
    description,
    action,
    // helpText,
    // helpLink,
}) => {
    const styles = useClasses();

    return (
        <Card className={styles.card}>
            <CardHeader className={styles.cardHeader}
                image={<img className={styles.logo} src={image} alt={`${header} icon`} />}
                header={<Body1 className={styles.header}><b>{header}</b></Body1>}
                description={<Caption1 className={styles.caption}>{secondaryText}</Caption1>}

            />
            <CardPreview>


                <div className={styles.text}>
                    {description}{' '}
                    {/* {
                    <p className={styles.text}>
                        {helpText}{' '}
                        {helpLink && (
                            <>
                                Click{' '}
                                <a href={helpLink} target="_blank" rel="noreferrer noopener">
                                    here
                                </a>
                                .
                            </>
                        )}
                    </p>
                } */}
                </div>
            </CardPreview>
            <CardFooter action={action} />
        </Card>
    );
};
