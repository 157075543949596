import { Button, Image, Subtitle1 } from '@fluentui/react-components';
import { useCallback } from 'react';
import { AuthHelper } from '../..//libs/auth/AuthHelper';
import { AppState, useClasses } from '../../App';
import { UserSettingsMenu } from '../header/UserSettingsMenu';
import { PluginGallery } from '../open-api-plugins/PluginGallery';
import { BackendProbe, ChatView, Error, Loading } from '../views';
import OntLogo from '../../assets/icons/ont_logo_trans.png'
import { Recent24 } from '../shared/BundledIcons';
import { useChat } from '../../libs/hooks';
import { RootState } from '../../redux/app/store';
import { useAppSelector } from '../../redux/app/hooks';

const Chat = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const onBackendFound = useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                AppState.LoadingChats,
        );
    }, [setAppState]);

    // const [isOpen, setIsOpen] = useState(false);
    const { toggleDrawer } = useChat();
    const { isDrawerOpen } = useAppSelector((state: RootState) => state.app);

    const onDrawerIconClick = () => {
        // setIsOpen((prev) => !prev)
        toggleDrawer(!isDrawerOpen);
    };

    return (
        <div className={classes.container}>
            <div className={`${classes.header} header`}>
                <div className={`${classes.logoPosition} logoPosition`}>
                    <Button icon={<Recent24 />} className={`${classes.drawerIcon} drawerIcon`} appearance="transparent" onClick={onDrawerIconClick} />
                    <Subtitle1 as="h1" className={`${classes.logo} logo`}>
                        {/* <span className={classes.logo}>On</span> */}
                        {/* telligent */}
                        {/* <img src={image} alt={`${header} icon`} /> */}
                        <Image src={OntLogo} width={180} />
                    </Subtitle1>
                </div>

                {appState > AppState.SettingUserInfo && (
                    <div className={classes.cornerItems}>
                        <div className={classes.cornerItems}>
                            <PluginGallery />
                            <UserSettingsMenu
                                setLoadingState={() => {
                                    setAppState(AppState.SigningOut);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
            {appState === AppState.Chat && <ChatView />}
        </div>
    );
};
export default Chat;
